
import { defineComponent } from "vue";
import AxiosService from "@/service/axios.service";
import { useStore } from "vuex";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import BaseHeader from "@/components/BaseHeader2.vue";
import InputBox from "@/components/member/InputBox.vue";
import TextButton from "@/components/common/TextButton.vue";
import CameraIcon from "icons/Camera.vue";

const { withAsync } = helpers;

function isUsableNickname(nickname: string): Promise<boolean> {
  if (nickname === "") return new Promise(() => true);
  return AxiosService.instance("/member/availability", {
    params: { nickname },
  }).then((result) => {
    let isValid = result.data as { nickname: boolean; email: boolean };
    if (nickname === "") {
      isValid.nickname = true;
    }

    return isValid.nickname;
  });
}

const hasSpace = helpers.regex(/^[^\s]*$/);

export default defineComponent({
  name: "ProfileModify",
  components: {
    BaseHeader,
    InputBox,
    TextButton,
    CameraIcon,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const store = useStore();
    return {
      store,
      profile: "",
      nickname: "",
    };
  },
  validations() {
    return {
      nickname: {
        required,
        isUsableNickname: withAsync(isUsableNickname),
        hasSpace,
      },
    };
  },
  computed: {
    member() {
      return this.store.getters["member/member"];
    },
  },
  methods: {
    changeProfile(e: Event) {
      const target = e.target as HTMLInputElement;
      if (FileReader && target.files && target.files.length > 0) {
        let fr = new FileReader();
        fr.onload = () => {
          this.profile = fr.result as string;
        };

        fr.readAsDataURL(target.files[0]);
      }
    },
    save() {
      let form = new FormData();

      const previewProfile = this.$refs
        .previewProfile as HTMLInputElement | null;
      if (
        previewProfile &&
        previewProfile.files &&
        previewProfile.files.length > 0
      ) {
        form.append("thumbnail", previewProfile.files[0]);
      }
      form.append("nickname", this.nickname);

      this.$axios
        .post(`/member/personal`, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$pushGAEvent("profile_edit");
          this.store.commit("main/setIsLoading", false);
          this.$axios.get("/member/me").then((res) => {
            this.store.dispatch("member/updateToken", res.data).then(() => {
              this.$flutter.callHandler("showToast", {
                message: "사용자 정보를 변경했습니다",
              });

              const member = this.store.getters["member/member"];
              this.$router.replace({
                path: "/profile",
                query: {
                  member: member.id,
                  nickname: member.nickname,
                  thumbnail: member.thumbnail,
                },
              });
            });
          });
        })
        .catch(() => {
          this.store.commit("main/setIsLoading", false);
        });
    },
  },
  mounted() {
    let user = this.store.getters["member/member"];
    if (user?.nickname) this.nickname = user.nickname;
    if (user?.thumbnail) this.profile = user.thumbnail || null;
  },
});
